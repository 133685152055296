<script>
import Handsontable from "handsontable";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  data: function () {
    var self = this;
    return {
      cachedArticulosCodigo: {},
      cachedArticulosEan13: {},
      defaultData: {
        recargo_equivalencia_sre: 0,
      },
      years: []
    };
  },
  computed: {
    sum_bi() {
      var self = this;
      let res =
        floatOrZero(self.formData.baseimponible_sre) +
        floatOrZero(self.formData.baseimponible_re) +
        floatOrZero(self.formData.baseimponible_metal);
      if (!isFinite(res)) res = 0;
      return res.toFixed(2);
    },
    sum_iva() {
      var self = this;
      let res =
        floatOrZero(self.formData.iva_re) +
        floatOrZero(self.formData.iva_sre) +
        floatOrZero(self.formData.iva_metal);
      if (!isFinite(res)) res = 0;
      return res.toFixed(2);
    },
    sum_re() {
      var self = this;
      let res =
        floatOrZero(self.formData.recargo_equivalencia_re) +
        floatOrZero(self.formData.recargo_equivalencia_sre) +
        floatOrZero(self.formData.recargo_equivalencia_metal);
      if (!isFinite(res)) res = 0;
      return res.toFixed(2);
    },
    precioMedioPieza() {
      var self = this;
      var total_unidades = floatOrZero(self.formData.total_unidades);
      var total = floatOrZero(self.formData.total);
      var precio_medio_pieza = total / total_unidades;
      if (!isFinite(precio_medio_pieza)) precio_medio_pieza = 0;
      return precio_medio_pieza.toFixed(2);
    },
  },
  methods: {
    templateMetalId: function (item) {
      if (!item.codigo) return "";
      return window.$(
        '<span style="display:flex"><span class="codelabel">' +
        item.metal +
        "</span> " +
        item.descripcion +
        "</span>"
      );
    },
    refrescarColumnaMPL(actualizarValor = false) {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      if (parseInt(self.formData.mermaporlinea)) {
        let idxPeso = self.$refs.lineas.field.columnsData.findIndex(
          (currentValue) => currentValue.name == "peso"
        );
        if (idxPeso != -1) {
          self.$refs.lineas.field.columnsData.splice(idxPeso + 1, 0, {
            name: "mpl",
            header: "MPL(%)",
            help: "Merma por línea",
            type: "numeric",
            numericFormat: { pattern: "0,0.000" },
            sufix: "%",
          });
          h.getSourceData().forEach(function (item, i) {
            if (item["articulo_id"] && actualizarValor) {
              //h.setDataAtRowProp(i, 'mpl', (self.formData.merma || 0), 'auto');
              h.getSourceData()[i].mpl = floatOrZero(
                self.formData.merma
              ).toFixed(2);
            }
          });
        }
      } else {
        let idxMpl = self.$refs.lineas.field.columnsData.findIndex(
          (currentValue) => currentValue.name == "mpl"
        );
        if (idxMpl != -1) {
          h.spliceCol(h.propToCol("mpl"), 0, h.countRows());
          self.$refs.lineas.field.columnsData.splice(idxMpl, 1);
        }
      }
      self.actualizar_gmerma();
    },
    descargarImagenes(pars) {
      var self = this;
      window.open(
        window.DB.server +
        self.dbAdapter +
        "?" +
        window.$.param({
          ...{
            action: "descargarImagenes",
            id: self.itemId,
            token: self.app.session.token,
          },
          ...pars,
        }),
        "_blank"
      );
    },
    onChange(arrProps, callback) {
      this.$on("change", function (prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    },
    actualizar_vencimientos() {
      let self = this;
      if (!self.formData.formapago_id || !self.formData.fecha) return;
      if (!self.$refs.vencimientos) return;
      let padLeft = (n) => ("00" + n).slice(-2);
      let total = floatOrZero(self.formData.total);
      let nvencimientos = floatOrZero(self.formData.formapago_id.vencimientos) || 1;
      let periodicidad = floatOrZero(self.formData.formapago_id.periodicidad);
      let periodicidad_unidades = self.formData.formapago_id.periodicidad_unidades || "days";
      var d = new Date(self.formData.fecha);
      const siguientePeriodo = () => {
        if (periodicidad_unidades == "weeks") {
          d.setDate(d.getDate() + periodicidad * 7);
        } else if (periodicidad_unidades == "months") {
          d.setMonth(d.getMonth() + periodicidad);
        } else {
          d.setDate(d.getDate() + periodicidad);
        }
      }
      let orig_vencimientos = self.formData.vencimientos || [];
      let vencimientos_remesados = orig_vencimientos.filter(v => v.remesa?.id);
      if (nvencimientos <= vencimientos_remesados.length) {
        self.app.toast("Existe un número de vencimientos remesados mayor o igual que el número de giros definidos en la forma de pago.", "error");
        return;
      }
      total -= vencimientos_remesados.reduce((acc, v) => acc += v.importe, 0);
      nvencimientos -= vencimientos_remesados.length;
      let div = (total / nvencimientos).toFixed(2);
      var res = vencimientos_remesados;
      for (let k = 0; k < vencimientos_remesados.length; k++) {
        siguientePeriodo();
      }
      for (let j = 0; j < nvencimientos; j++) {
        siguientePeriodo();
        res.push({
          fecha: [
            d.getFullYear(),
            padLeft(d.getMonth() + 1),
            padLeft(d.getDate()),
          ].join("-"),
          importe:
            j == nvencimientos - 1 ? total - (nvencimientos - 1) * div : div,
        });
      }
      self.$set(self.formData, "vencimientos", res);
    },
    actualizar_g24k_porcentaje() {
      let self = this;
      var t =
        (floatOrZero(self.formData.g18k) + floatOrZero(self.formData.gmerma)) *
        floatOrZero(self.formData.factor);
      self.$set(self.formData, "g24k_porcentaje", t.toFixed(3));
      self.$emit("change", "g24k_porcentaje", "auto");
    },
    actualizar_total_unidades() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let t = h
        .getDataAtProp("unidades")
        .reduce(
          (accumulator, currentValue) =>
            accumulator + floatOrZero(currentValue),
          0
        );
      self.$set(self.formData, "total_unidades", t);
      self.$emit("change", "total_unidades", "auto");
    },
    actualizar_total_peso() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let t = h
        .getDataAtProp("peso")
        .reduce(
          (accumulator, currentValue) =>
            accumulator + floatOrZero(currentValue),
          0
        );
      self.$set(self.formData, "total_peso", t.toFixed(3));
      self.$emit("change", "total_peso", "auto");
    },
    actualizar_g18k() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      var arr_m2 = h.getDataAtProp("m2");
      var t = 0;
      if (
        self.formData.tipo_metal == "cuenta" ||
        self.formData.tipo_metal == "factura"
      ) {
        h.getDataAtProp("peso").forEach(function (item, i) {
          if (item && parseInt(arr_m2[i])) {
            t += floatOrZero(item);
          }
        });
      }
      self.$set(self.formData, "g18k", t.toFixed(3));
      self.$emit("change", "g18k", "auto");
    },
    actualizar_gcontraste_porcentaje() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      var arr_c2 = h.getDataAtProp("c2");
      var t = 0;
      h.getDataAtProp("peso").forEach(function (item, i) {
        if (item && parseInt(arr_c2[i])) {
          t += floatOrZero(item);
        }
      });
      self.$set(self.formData, "gcontraste_porcentaje", t.toFixed(3));
      self.$emit("change", "gcontraste_porcentaje", "auto");
    },
    actualizar_gotroscostes_porcentaje() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      var arr_o = h.getDataAtProp("o");
      var t = 0;
      h.getDataAtProp("peso").forEach(function (item, i) {
        if (item && parseInt(arr_o[i])) {
          t += floatOrZero(item);
        }
      });
      self.$set(self.formData, "gotroscostes_porcentaje", t.toFixed(3));
      self.$emit("change", "gotroscostes_porcentaje", "auto");
    },
    actualizar_total_articulos() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      var t = 0;
      var t_re = 0;
      var t_sre = 0;
      var re_arr = h.getDataAtProp("r");
      let rec = self.tieneRecargoEquivalencia();
      //window.console.log(re_arr);
      h.getDataAtProp("totallinea").forEach(function (item, i) {
        if (item) {
          t += floatOrZero(item);
          if (parseInt(re_arr[i]) && rec) {
            t_re += floatOrZero(item);
          } else {
            t_sre += floatOrZero(item);
          }
        }
      });
      self.$set(self.formData, "total_articulos", t.toFixed(2));
      self.$set(self.formData, "total_articulos_re", t_re.toFixed(2));
      self.$set(self.formData, "total_articulos_sre", t_sre.toFixed(2));
      self.$emit("change", "total_articulos", "auto");
      self.$emit("change", "total_articulos_re", "auto");
      self.$emit("change", "total_articulos_sre", "auto");
    },
    actualizar_gmerma() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let val = (row, columna) => h.getDataAtRowProp(row, columna) || 0;
      var mermaporlinea = parseFloat(self.formData.mermaporlinea) || 0;
      var arr_m2 = h.getDataAtProp("m2");
      var t = 0;
      h.getDataAtProp("peso").forEach(function (item, i) {
        var merma = mermaporlinea
          ? floatOrZero(val(i, "mpl"))
          : floatOrZero(self.formData.merma || 0);
        if (item && parseInt(arr_m2[i])) {
          t += floatOrZero(item) * (floatOrZero(merma) / 100);
        }
      });
      self.$set(self.formData, "gmerma", t.toFixed(3));
      self.$emit("change", "gmerma", "auto");
    },
    actualizar_estadisticas_familias() {
      var self = this;
      if (!self.$refs.estadisticas_familias) return;
      let h = self.$refs.lineas.field.hotInstance;
      let hf = self.$refs.estadisticas_familias.field.hotInstance;
      hf.cachedItems = hf.cachedItems || {};
      hf.cachedItems.familia_id = hf.cachedItems.familia_id || {};
      var datos = {};
      var darr = [];
      h.getSourceData().forEach(function (line, i) {
        if (line["articulo_id"]) {
          if (typeof line["articulo_id"] == "object") {
            if (!line["articulo_id"]["familia_id"]) return;
            var id = line["articulo_id"]["familia_id"]["codigo"];
            hf.cachedItems.familia_id[id] = line["articulo_id"]["familia_id"];
          } else {
            if (
              !h.cachedItems["articulo_id"][line["articulo_id"]] ||
              !h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"]
            )
              return;
            id =
              h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"][
              "codigo"
              ];
            hf.cachedItems.familia_id[id] =
              h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"];
          }
          if (id in datos) {
            (datos[id].unidades += floatOrZero(line["unidades"])),
              (datos[id].precio +=
                floatOrZero(line["precio"]) * floatOrZero(line["unidades"]));
          } else {
            datos[id] = {
              unidades: floatOrZero(line["unidades"]),
              precio:
                floatOrZero(line["precio"]) * floatOrZero(line["unidades"]),
            };
          }
        }
      });
      for (let fid in datos) {
        let d = datos[fid];
        darr.push({
          familia_id: fid,
          unidades: d.unidades,
          precio_medio: (d.precio / d.unidades).toFixed(2),
        });
      }
      self.$set(self.formData, "estadisticas_familias", darr);
    },
    actualizar_taqueria() {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let ht = self.$refs.taqueria_lineas?.field.hotInstance;
      if (!ht) return;
      var datos = {}; //Cada clave es un id de taqueria

      if (!['pendiente', 'procesando'].includes(self.formData.estado)) return;

      if (self.formData.calculo_taqueria == 'lineas') {
        let htData = ht.getSourceData();

        h.getSourceData().forEach(function (line) {
          if (line["taqueria_ids"]) {
            if (typeof line["taqueria_ids"] === "object")
              var arrids = line["taqueria_ids"].map((el) => el.codigo);
            else arrids = line["taqueria_ids"].split(",");
            arrids.forEach(function (id) {
              if (
                !(
                  id in h.cachedItems.taqueria_ids &&
                  h.cachedItems.taqueria_ids[id]
                )
              )
                return;
              if (id in datos) {
                datos[id] += floatOrZero(line["unidades"]);
              } else {
                datos[id] = floatOrZero(line["unidades"]);
              }
            });
          }
        });
        /*htData.forEach(function (linetaq, i) {
          var tid = linetaq["taqueria_id"];
          if (tid in datos) {
            linetaq["unidades_teoricas"] = datos[tid];
            linetaq["unidades_reales"] = datos[tid];
            delete datos[tid];
          } else if (tid) {
            linetaq["unidades_teoricas"] = 0;
            linetaq["unidades_reales"] = 0;
          }
        });
        htData = htData.filter((el) => Object.keys(el).length != 0);
        */
        htData = Object.keys(datos).map((id) => ({
          taqueria_id: id,
          unidades_teoricas: datos[id],
          unidades_reales: datos[id],
        }));
        htData.sort((a, b) => a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0);
        //ht.cachedItems.taqueria_id = {...ht.cachedItems.taqueria_id, ...h.cachedItems.taqueria_ids };
        self.$set(self.formData, "taqueria_lineas", htData);

      } else if (self.formData.calculo_taqueria == 'reglas') {
        let hrt = self.$refs.reglas_taqueria?.field.hotInstance;
        if (!hrt) return;
        h.getSourceData().forEach(function (line) {
          hrt.getSourceData().forEach(function (r) {
            if (floatOrZero(line.precio) >= r.precio_minimo && floatOrZero(line.precio) <= r.precio_maximo) {
              let t_ids = typeof r["taqueria_ids"] === "object" ? r["taqueria_ids"].map((el) => el.codigo) : r["taqueria_ids"].split(",");
              t_ids.forEach(t_id => {
                datos[t_id] = datos[t_id] || 0;
                datos[t_id] += floatOrZero(line["unidades"]);
              })
            }
          });
        });
        let htData = Object.keys(datos).map((id) => ({
          taqueria_id: id,
          unidades_teoricas: datos[id],
          unidades_reales: datos[id]
        }));
        htData.sort((a, b) => a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0);
        self.$set(self.formData, "taqueria_lineas", htData);
      }


    },
    actualizar_taqueria_precio(row) {
      var self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let taqueria_precio = 0;
      let taqueria_ids = h.getDataAtRowProp(row, "taqueria_ids");
      ((taqueria_ids && taqueria_ids.split(",")) || []).forEach(function (t) {
        if (h.cachedItems.taqueria_ids[t])
          taqueria_precio +=
            parseFloat(h.cachedItems.taqueria_ids[t].coste_unidad_venta) || 0;
      });
      h.getSourceData()[row].taqueria_precio = taqueria_precio;
      if (self.actualizar_pventa) self.actualizar_pventa(row);
    },
    rendererRojoNegativo(instance, td, row, col, prop, value, cellProperties) {
      let h = instance;
      var precio = floatOrZero(h.getSourceData()[row]["precio"]);
      var precio_coste = floatOrZero(h.getSourceData()[row]["precio_coste"]);
      if (precio < precio_coste) td.style.color = "red";
      else td.style.color = "grey";
      td.style.backgroundColor = '#f2f2f2';
      Handsontable.renderers.NumericRenderer.apply(this, arguments);
    },
    rendererNaranjaPrecioModificado(instance, td, row, col, prop, value, cellProperties) {
      let h = instance;
      if (h.getSourceData()[row].precio_modificado) td.style.color = "orange";
      Handsontable.renderers.NumericRenderer.apply(this, arguments);
    },
    importLines() {
      var self = this;
      self.app.openChildWindow("import_lines", self.win, {
        backdrop: true,
        selectable: true,
        model: self.dbAdapter,
        onSelect: function (lineas) {
          if (!self.formData.lineas) self.$set(self.formData, "lineas", []);
          lineas.forEach(function (linea) {
            self.formData.lineas.push(linea);
          });
          self.$forceUpdate();
          self.actualizarTodo(true);
        },
      });
    },
    exportarLineasCSV(field) {
      var self = this;
      window.open(
        window.DB.server +
        "linea_" +
        self.dbAdapter +
        "?" +
        window.$.param({
          action: "exportCSV",
          filter: [field, "=", self.itemId],
          token: self.app.session.token,
        }),
        "_blank"
      );
    },
    rendererLineasAlbaranGeneradas(h, td, row) {
      let col = "lineas_albaran_generadas";
      let parent = "albaran_id";
      let dataCell = h.getSourceDataAtRow(row)[col];
      td.innerText =
        (typeof dataCell == 'string' ? dataCell : '').split(",")
          .map((lid) => lid && h.cachedItems[col][lid][parent].ndoc) || "";
    },
    rendererLineasFacturaGeneradas(h, td, row) {
      let col = "lineas_factura_generadas";
      let parent = "factura_id";
      let dataCell = h.getSourceDataAtRow(row)[col];
      td.innerText =
        (typeof dataCell == 'string' ? dataCell : '').split(",")
          .map((lid) => lid && h.cachedItems[col][lid][parent].ndoc) || "";
    },
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function (e) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search") return;
        self.$set(self.formData, "wt", !floatOrZero(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });

    //Al seleccionar la divisa
    self.onChange(["divisa_id"], function (source) {
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.divisa_id) return;
      self.$set(
        self.formData,
        "cambio",
        floatOrZero(self.formData.divisa_id.ratio).toFixed(3)
      );
      self.$emit("change", "cambio", "auto");
    });
    //Al seleccionar el metal
    self.onChange(["metal_id"], function (source) {
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.metal_id) return;
      self.$set(
        self.formData,
        "prmetalp",
        (
          floatOrZero(self.formData.metal_id.cotizacion) *
          floatOrZero(self.formData.cambio)
        ).toFixed(4)
      );
      self.$set(
        self.formData,
        "factor",
        floatOrZero(self.formData.metal_id.factor).toFixed(3)
      );
      self.$emit("change", "prmetalp", "auto");
    });
    //Al cambiar el tipo de metal
    if (self.$refs.tipo_metal) {
      self.$refs.tipo_metal.field.$on("change", function () {
        if (self.formData.tipo_metal == "no") {
          self.$set(self.formData, "metal_id", null);
          self.$set(self.formData, "factor", null);
          self.$set(self.formData, "prmetalp", null);
          self.$set(self.formData, "merma", null);
          self.$emit("change", "prmetalp", "auto");
          self.$emit("change", "merma", "auto");
        }
      });
    }

    //Al cambiar el checkbox de Merma por línea
    self.onChange(["mermaporlinea"], function (source) {
      self.refrescarColumnaMPL(true);
    });

    self.$on("loadItem", () => {
      self.refrescarColumnaMPL();
    });

    //Al cambiar Vencimientos
    if (self.$refs.vencimientos) {
      self.$refs.vencimientos.field.hotInstance.addHook(
        "beforeChange",
        function (changes, source) {
          if (source == "loadData") return;
          if (!self.formData.cambio) {
            self.app.toast("Introduce la divisa e indica el cambio", "error");
            return false;
          }
        }
      );
      self.$refs.vencimientos.field.hotInstance.addHook(
        "afterChange",
        function (changes, source) {
          if (source == "loadData") return;
          if (!changes) return;
          var h_ven = this;
          changes.forEach(function (change, index) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];

            if (prop == "importe" && oldVal != newVal && source != "auto") {
              var total = floatOrZero(self.formData.total);
              var imp = h_ven.getDataAtProp("importe");
              var fec = h_ven.getDataAtProp("fecha");
              var acum = 0;
              var n = 0;
              imp.forEach(function (item, i) {
                if (fec[i]) {
                  if (i <= row) {
                    acum += floatOrZero(item) || 0;
                  }
                  n += 1;
                }
              });
              if (row + 1 == n) {
                //Si se edita la última fila
                h_ven.setDataAtRowProp(row, "importe", oldVal, "auto");
                return;
              }
              if (acum > total) {
                h_ven.setDataAtRowProp(row, "importe", oldVal, "auto");
                return;
              }
              var div = ((total - acum) / (n - row - 1)).toFixed(2);
              imp.forEach(function (item, i) {
                if (i > row && i < n - 1) {
                  h_ven.setDataAtRowProp(i, "importe", div, "auto");
                } else if (i > row && i == n - 1) {
                  //última fila lo que reste, porque pueden quedar decimales
                  h_ven.setDataAtRowProp(
                    i,
                    "importe",
                    total - acum - (n - row - 2) * div,
                    "auto"
                  );
                }
              });
            }
          });
        }
      );
    }
    /*self.$refs.vencimientos.field.hotInstance.addHook(
      "afterSelection",
      function(r, c, r2, c2) {
        var h = this;
        if (h.isEmptyRow(r)) {
          h.selectCell(r - 1, c);
        }
        if (r > 0 && h.isEmptyRow(r - 1)) {
          window.console.log(12421);
        }
      }
    );*/

    //PIE
    self.onChange(["g18k", "gmerma", "factor"], function (source) {
      self.actualizar_g24k_porcentaje();
    });
    self.onChange(["merma"], function (source) {
      self.actualizar_gmerma();
    });
    self.onChange(["g24k_porcentaje", "prmetalp"], function (source) {
      self.$set(
        self.formData,
        "g24k",
        (self.formData.tipo_metal == "cuenta"
          ? 0
          : floatOrZero(self.formData.g24k_porcentaje) *
          floatOrZero(self.formData.prmetalp) || 0
        ).toFixed(2)
      );

      self.$emit("change", "g24k", "auto");
    });
    self.onChange(["gcontraste_porcentaje", "contraste"], function (source) {
      self.$set(
        self.formData,
        "gcontraste",
        (
          floatOrZero(self.formData.gcontraste_porcentaje) *
          floatOrZero(self.formData.contraste) || 0
        ).toFixed(3)
      );

      self.$emit("change", "gcontraste", "auto");
    });
    self.onChange(["gotroscostes_porcentaje", "ocostes"], function (source) {
      self.$set(
        self.formData,
        "gotroscostes",
        (
          floatOrZero(self.formData.gotroscostes_porcentaje) *
          floatOrZero(self.formData.ocostes) || 0
        ).toFixed(3)
      );

      self.$emit("change", "gotroscostes", "auto");
    });
    // Sólo para venta?¿?
    self.onChange(["uotroscostes_porcentaje", "ocostesu"], function (source) {
      self.$set(
        self.formData,
        "uotroscostes",
        (
          floatOrZero(self.formData.uotroscostes_porcentaje) *
          floatOrZero(self.formData.ocostesu) || 0
        ).toFixed(2)
      );
      self.$emit("change", "uotroscostes", "auto");
    });
    // Descuento especial
    self.onChange(
      ["dto_especial_porcentaje", "total_articulos"],
      function (source) {
        self.$set(
          self.formData,
          "dto_especial",
          (
            (floatOrZero(self.formData.total_articulos) *
              floatOrZero(self.formData.dto_especial_porcentaje)) /
            100
          ).toFixed(2)
        );
        //self.$emit('change', 'dto_especial', 'auto');
      }
    );
    self.onChange(["dto_especial", "total_articulos"], function (source) {
      if (source == "auto") return;
      self.$set(
        self.formData,
        "dto_especial_porcentaje",
        (self.formData.total_articulos
          ? (self.formData.dto_especial * 100) / self.formData.total_articulos
          : 0
        ).toFixed(4)
      );
      self.$emit("change", "dto_especial_porcentaje", "auto");
    });

    //Financiacion
    self.onChange(
      ["financiacion_porcentaje", "baseimponible_re", "baseimponible_sre"],
      function (source) {
        let base =
          floatOrZero(self.formData.baseimponible_sre) +
          floatOrZero(self.formData.baseimponible_re);
        let financiacion = (
          (floatOrZero(base) *
            floatOrZero(self.formData.financiacion_porcentaje)) /
          100
        ).toFixed(2);
        self.$set(self.formData, "financiacion", financiacion);
        self.$emit("change", "financiacion", "auto");
      }
    );
    self.onChange(["financiacion"], function (source) {
      if (source == "auto") return;
      let base =
        floatOrZero(self.formData.baseimponible_sre) +
        floatOrZero(self.formData.baseimponible_re) +
        floatOrZero(self.formData.baseimponible_metal);
      self.$set(
        self.formData,
        "financiacion_porcentaje",
        (base
          ? floatOrZero((self.formData.financiacion * 100) / base)
          : 0
        ).toFixed(4)
      );
      //self.$emit('change', 'financiacion_porcentaje', 'auto');
    });

    //Calcular BASE SRE
    self.onChange(
      [
        "total_articulos_sre",
        "dto_especial_porcentaje",
        "g24k",
        "gcontraste",
        "gotroscostes",
        "uotroscostes",
        "costes_adicionales",
        "portesyseguros",
        "taqueria",
      ],
      function (source) {
        //TODO financiacion_porcentaje???
        let base_sre =
          floatOrZero(self.formData.total_articulos_sre) *
          (1 -
            floatOrZero(self.formData.dto_especial_porcentaje) /
            100) /** (1 + floatOrZero(financiacion_porcentaje)/100)*/ +
          floatOrZero(self.formData.costes_adicionales) +
          floatOrZero(self.formData.portesyseguros) +
          floatOrZero(self.formData.taqueria);
        if (!self.tieneRecargoEquivalencia()) {
          if (self.formData.tipo_metal != "cuenta") {
            base_sre += floatOrZero(self.formData.g24k);
          }
          base_sre +=
            floatOrZero(self.formData.gcontraste) +
            floatOrZero(self.formData.gotroscostes) +
            floatOrZero(self.formData.uotroscostes);
        }
        if (!isFinite(base_sre)) base_sre = 0;
        //window.console.log(base_sre);
        self.$set(self.formData, "baseimponible_sre", base_sre.toFixed(2));
        self.$emit("change", "baseimponible_sre", "auto");
      }
    );
    //Calcular BASE RE
    self.onChange(
      [
        "total_articulos_re",
        "dto_especial_porcentaje",
        "g24k",
        "gcontraste",
        "gotroscostes",
        "uotroscostes",
      ],
      function (source) {
        let base_re =
          floatOrZero(self.formData.total_articulos_re) *
          (1 - floatOrZero(self.formData.dto_especial_porcentaje) / 100);
        if (self.tieneRecargoEquivalencia()) {
          if (self.formData.tipo_metal != "cuenta") {
            base_re += floatOrZero(self.formData.g24k);
          }
          base_re +=
            floatOrZero(self.formData.gcontraste) +
            floatOrZero(self.formData.gotroscostes) +
            floatOrZero(self.formData.uotroscostes);
        }
        if (!isFinite(base_re)) base_re = 0;
        self.$set(self.formData, "baseimponible_re", base_re.toFixed(2));
        self.$emit("change", "baseimponible_re", "auto");
      }
    );
    //Calcular BASE METAL
    self.onChange(
      ["tipo_metal", "g24k", "gcontraste", "gotroscostes", "uotroscostes"],
      function (source) {
        var base_metal = 0;
        if (self.formData.tipo_metal == "cuenta") {
          base_metal =
            floatOrZero(self.formData.g24k_porcentaje) *
            floatOrZero(self.formData.prmetalp) || 0;
        }
        if (!isFinite(base_metal)) base_metal = 0;
        self.$set(self.formData, "baseimponible_metal", base_metal.toFixed(2));
        self.$emit("change", "baseimponible_metal", "auto");
      }
    );
    //Calcular IVA
    self.onChange(["baseimponible_sre", "ivap", "wt"], function (source) {
      let nwt = floatOrZero(self.formData.wt) ? 0 : 1;
      self.$set(self.formData, "iva_sre", (((floatOrZero(self.formData.baseimponible_sre) * floatOrZero(self.formData.ivap)) / 100) * nwt).toFixed(2));
      self.$emit("change", "iva_sre", "auto");
    });
    self.onChange(["baseimponible_re", "ivap", "wt"], function (source) {
      let nwt = floatOrZero(self.formData.wt) ? 0 : 1;
      self.$set(self.formData, "iva_re", (((floatOrZero(self.formData.baseimponible_re) * floatOrZero(self.formData.ivap)) / 100) * nwt).toFixed(2));
      self.$emit("change", "iva_re", "auto");
    });
    self.onChange(["baseimponible_metal", "ivap", "wt"], function (source) {
      let nwt = floatOrZero(self.formData.wt) ? 0 : 1;
      self.$set(self.formData, "iva_metal", (((floatOrZero(self.formData.baseimponible_metal) * floatOrZero(self.formData.ivap)) / 100) * nwt).toFixed(2));
      self.$emit("change", "iva_metal", "auto");
    });
    //Calcular RE
    self.onChange(["baseimponible_re", "rep", "wt"], function (source) {
      let nwt = floatOrZero(self.formData.wt) ? 0 : 1;
      self.$set(
        self.formData,
        "recargo_equivalencia_re",
        (
          ((floatOrZero(self.formData.baseimponible_re) *
            floatOrZero(self.formData.rep)) /
            100) * nwt
        ).toFixed(2)
      );
      self.$emit("change", "recargo_equivalencia_re", "auto");
    });
    self.onChange(["baseimponible_metal", "rep", "wt"], function (source) {
      let nwt = floatOrZero(self.formData.wt) ? 0 : 1;
      self.$set(
        self.formData,
        "recargo_equivalencia_metal",
        (
          ((floatOrZero(self.formData.baseimponible_metal) *
            floatOrZero(self.formData.rep)) /
            100) * nwt *
          self.tieneRecargoEquivalencia()
        ).toFixed(2)
      );
      self.$emit("change", "recargo_equivalencia_metal", "auto");
    });
    //Calcular TOTAL1
    self.onChange(
      ["baseimponible_re", "iva_re", "recargo_equivalencia_re"],
      function (source) {
        self.$set(
          self.formData,
          "total1",
          (
            floatOrZero(self.formData.baseimponible_re) +
            floatOrZero(self.formData.iva_re) +
            floatOrZero(self.formData.recargo_equivalencia_re)
          ).toFixed(2)
        );
        self.$emit("change", "total1", "auto");
      }
    );
    //Calcular TOTAL2
    self.onChange(
      ["baseimponible_sre", "iva_sre", "recargo_equivalencia_sre"],
      function (source) {
        self.$set(
          self.formData,
          "total2",
          (
            floatOrZero(self.formData.baseimponible_sre) +
            floatOrZero(self.formData.iva_sre) +
            floatOrZero(self.formData.recargo_equivalencia_sre)
          ).toFixed(2)
        );
        self.$emit("change", "total2", "auto");
      }
    );
    //Calcular TOTAL METAL
    self.onChange(
      [
        "baseimponible_metal",
        "iva_metal",
        "recargo_equivalencia_metal",
        "base_metal_check",
      ],
      function (source) {
        self.$set(
          self.formData,
          "total_metal",
          (!parseInt(self.formData.base_metal_check || 0)
            ? 0
            : floatOrZero(self.formData.iva_metal) +
            floatOrZero(self.formData.recargo_equivalencia_metal)
          ).toFixed(2)
        );
        self.$emit("change", "total_metal", "auto");
      }
    );
    //Calcular TOTAL
    self.onChange(
      ["total1", "total2", "total_metal", "financiacion"],
      function (source) {
        self.$set(
          self.formData,
          "total",
          (
            floatOrZero(self.formData.total1) +
            floatOrZero(self.formData.total2) +
            floatOrZero(self.formData.total_metal) +
            floatOrZero(self.formData.financiacion)
          ).toFixed(2)
        );
        self.$emit("change", "total", "auto");
      }
    );
    // Al cambiar la forma de pago
    self.onChange(["formapago_id", "total", "fecha"], function (source) {
      self.actualizar_vencimientos();
    });

    // Líneas
    self.$refs.lineas.field.hotInstance.addHook("beforeKeyDown", function (e) {
      var keyCode = e.keyCode || e.which;
      var arrow = { left: 37, up: 38, right: 39, down: 40, tab: 9 };
      switch (keyCode) {
        case arrow.left:
        case arrow.up:
        case arrow.right:
        case arrow.down:
        case arrow.tab:
          return;
      }
      var h = this;
      var sel = h.getSelected();
      if (typeof sel == "undefined") return;
      var r = sel[0][0];
      var p = h.colToProp(sel[0][1]);
      if (
        p != "ean13" &&
        p != "articulo_id" && //!h.getDataAtRowProp(r, "ean13") ||
        p != "modelo_id" &&
        !h.getDataAtRowProp(r, "articulo_id")
      ) {
        //e.preventDefault();
        h.selectCell(r, "articulo_id");
      }
    });

    //Al cambiar líneas
    self.$refs.lineas.field.hotInstance.addHook(
      "beforeChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!self.formData.cambio) {
          self.app.toast("Introduce la divisa e indica el cambio", "error");
          return false;
        }
        if (source == "loadData" || source == "load") return;
        var h = this;

        // Comprobamos si es un copia-pega que incluye artículo
        let isPasteWithProduct =
          source == "CopyPaste.paste" &&
          changes.some(function (change) {
            let prop = change[1];
            return prop == "ean13" || prop == "articulo_id";
          });
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (
            prop != "ean13" &&
            prop != "articulo_id" &&
            prop != "modelo_id" &&
            (!h.getSourceData()[row] || !h.getSourceData()[row].articulo_id) &&
            !isPasteWithProduct
          ) {
            changes[index] = null;
          }
          // c2
          if (
            prop == "c2" &&
            newVal == 1 &&
            !parseInt(h.getSourceData()[row]["m2"])
          ) {
            changes[index] = null;
          }
          // Restringir campo tarifa. tt2 en venta. tt en compra
          /*if (prop == 'tt2') {
                    if (newVal == 'w') changes[index][3] = 'W';
                    else if ($.inArray(newVal, ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'W' ]) == -1) changes[index][3] = '0';
                    return;
                }*/
          // Restringir campo tipo de almacén (tt en venta)
          /*if (prop == 'tt') {
                    if (newVal == 'a') changes[index][3] = 'A';
                    else if (newVal == 'r') changes[index][3] = 'R';
                    else if (Array.inArray(newVal, ['A', 'R']) == -1) changes[index][3] = 'A';
                    return;
                }*/
          // Restringir campos c y v
          /*if (prop == 'c') {
                    if (newVal == 'd') changes[index][3] = 'D';
                    else if (newVal == 'i') changes[index][3] = 'I';
                    else if ($.inArray(newVal, ['D', 'I']) == -1) changes[index][3] = 'D';
                }
                if (prop == 'v') {
                    if (newVal == 'p') changes[index][3] = 'P';
                    else if (newVal == 'u') changes[index][3] = 'U';
                    else if ($.inArray(newVal, ['U', 'P']) == -1) changes[index][3] = 'U';
                }*/
          // Restringir campo etiquetas
          if (prop == "etiqueta") {
            if (newVal < 0) {
              changes[index][3] = "0";
            } else if (floatOrZero(newVal) % 1 !== 0) {
              changes[index][3] = Math.round(newVal);
            }
          }
          if (
            [
              "totallinea",
              "precio",
              "descuentos",
              "descuento_peso",
              "peso",
              "unidades",
              "margen",
              "pventa",
            ].includes(prop) &&
            newVal == null
          ) {
            if (changes[index]) changes[index][3] = 0;
          }
          // Al poner precio=0, dto=0
          if (prop == "precio" && parseFloat(newVal || 0) == 0) {
            changes.push([row, "descuentos", null, 0]);
          }
          // Forzar código artículo mayúsculas
          if (prop == "articulo_id" && newVal) {
            changes[index][3] = newVal.toUpperCase();
          }

          if ((prop == "tt2" || prop == "t") && newVal) {
            if (
              !Array.from(Array(10), (_, i) => i + 1).includes(
                parseInt(newVal || 0)
              )
            ) {
              changes[index][3] = null;
              self.app.toast("Tarifa incorrecta (1-10)", "error");
            }
          }
          if ((prop == "c" || prop == "v" || prop == "tt") && !newVal) {
            changes[index] = null;
          }
          if (prop == "tt") {
            if (!['A', 'R'].includes((newVal || '').toUpperCase())) {
              changes[index] = null;
            } else {
              changes[index][3] = newVal.toUpperCase();
            }
          }
        });
      }
    );
    /*self.$refs.lineas.field.hotInstance.addHook('beforeKeyDown', e => {
      window.console.log(e);
    })*/

    self.$on("loadItem", function () {
      self.actualizar_estadisticas_familias();
    });

    self.renderH = self.$utils.misc.debounce(function (h) {
      h.render();
    }, 200);

    window.DB.action(self.dbAdapter, 'getDistinctYears').then(res => self.years = res.data)
  },
};
</script>
<style>
.tabla-pie {
  border-top: 1px solid #ccc;
  padding: 0;
  border-collapse: collapse;
}

.tabla-pie input {
  text-align: center;
}

.tabla-pie tr {
  border-top: 1px solid #ccc;
}

.tabla-pie td {
  padding: 1px 5px;
  border-right: 1px solid #ccc;
}

.tabla-pie th {
  background: #f7f7f8;
  padding: 3px 5px;
}

.tabla-pie .total-cell {
  text-align: right;
}

.lineas .handsontable th,
.lineas .handsontable td,
.lineas .handsontableInput {
  height: 15px !important;
  line-height: 15px !important;
  border-bottom: transparent;
}

.lineas .autocomplete2-image {
  height: 14px;
}

.lineas .autocomplete2-image:hover {
  height: 100px;
}

.lineas .handsontable .htCheckboxRendererInput {
  vertical-align: initial;
  height: 10px;
  width: 10px;
}

.lineas .handsontable .htCheckboxRendererInput:before {
  font-size: 10px;
}

.lineas .ht_editor_visible {
  font-size: 11px;
}
</style>